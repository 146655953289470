import { Dialog, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import { CarouselPhotosDetails } from './carousel-photos-details'
import { CarouselPhoto } from './carousel-photos-details'
import { useTheme } from '@mui/material/styles'

type Props = {
  carouselPhotos: Array<CarouselPhoto>
  slideNumber: number | null
  setSlideNumber: (value: null) => void
}

export const CarouselPhotos = ({
  carouselPhotos,
  slideNumber,
  setSlideNumber,
}: Props) => {
  const [initialSlide, setInitialSlide] = useState<number | null>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setInitialSlide(slideNumber)
  }, [slideNumber])

  return (
    <Dialog
      sx={{ zIndex: 1500 }} //temporary
      PaperProps={{
        sx: {
          border: 'none',
          backgroundColor: { xs: 'common.black', sm: 'none' },
          pt: { xs: 7.5, sm: 0 },
          width: { xs: 'auto' },
        },
      }}
      onClose={() => {
        setInitialSlide(null)
        setSlideNumber(null)
      }}
      open={initialSlide !== null}
      scroll="body"
      fullScreen={isMobile ? true : false}
    >
      {initialSlide !== null && (
        <CarouselPhotosDetails
          carouselPhotos={carouselPhotos}
          initialSlide={initialSlide}
          onClose={() => {
            setInitialSlide(null)
            setSlideNumber(null)
          }}
        />
      )}
    </Dialog>
  )
}
