import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { IArtist, IImageResizedUrls } from 'src/api'
import { pages } from 'src/app/pages'
import { CountryFlag } from 'src/components/country-flag'
import { NextLink } from 'src/components/next-link'
import { ResponsiveImage, SrcSet } from 'src/components/responsive-image'
import { EnumType } from 'src/lib/types'

export enum CardSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

const cardSizes = {
  [CardSize.small]: {
    width: { xs: 178, sm: 210, lg: 233, xl: 258 },
    height: { xs: 92, sm: 107, lg: 150 },
    textPosition: { xs: 8, lg: 10, xl: 12 },
    typographyVariant: 'h4',
    borderRadius: { xs: '12px' },
    flagTitleSpacing: { xs: 0.5, sm: 0.75, lg: 0.5, xl: 1 },
    flagProportion: { xs: 12, sm: 16, lg: 18 },
  },
  [CardSize.medium]: {
    width: { xs: 218, sm: 250, lg: 316, xl: 350 },
    height: { xs: 120, sm: 135, lg: 176 },
    textPosition: 12,
    typographyVariant: 'h4',
    borderRadius: { xs: '12px' },
    flagTitleSpacing: { xs: 0.5, sm: 0.75, lg: 1 },
    flagProportion: { xs: 16, sm: 18 },
  },
  [CardSize.large]: {
    width: { xs: 268, sm: 300, lg: 481, xl: 535 },
    height: { xs: 150, sm: 165, lg: 220 },
    textPosition: { xs: 12, lg: 16 },
    typographyVariant: 'h3ExtraBold',
    borderRadius: { xs: '12px', lg: '14px' },
    flagTitleSpacing: { xs: 0.75, sm: 1 },
    flagProportion: { xs: 18, lg: 24 },
  },
} as const

const getArtistCardSrcSet = ({
  cardSize,
  resizedUrls,
}: {
  cardSize: EnumType<CardSize>
  resizedUrls: IImageResizedUrls
}): SrcSet => {
  switch (cardSize) {
    case 'small': {
      return {
        MOBILE: resizedUrls.SMALL,
      }
    }
    case 'medium':
    case 'large': {
      return {
        MOBILE: resizedUrls.SMALL,
        SMALL_DESKTOP: resizedUrls.MEDIUM,
      }
    }
  }
}

type Props = {
  artist: IArtist
  size: EnumType<CardSize>
}

export const ArtistCard = ({ artist, size }: Props) => {
  const {
    width,
    height,
    textPosition,
    typographyVariant,
    borderRadius,
    flagProportion,
    flagTitleSpacing,
  } = cardSizes[size]

  return (
    <NextLink
      href={pages['/artists/[artistSlug]']({
        artistSlug: artist.performerSlug,
      })}
      underline="none"
    >
      <Box
        width={width}
        height={height}
        position="relative"
        flexShrink={0}
        sx={{
          '& .eventArtist': {
            borderRadius,
            zIndex: 1,
          },
          cursor: 'pointer',
        }}
      >
        <Box
          position="absolute"
          width="100%"
          height="100%"
          sx={{
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 79.7%)',
            zIndex: 2,
            borderRadius,
          }}
        />
        <ResponsiveImage
          alt={artist.name}
          originalSizeUrl={artist.coverImage.url}
          srcSet={getArtistCardSrcSet({
            cardSize: size,
            resizedUrls: artist.coverImage.resizedUrls,
          })}
          className="eventArtist"
        />
        <Stack
          direction="row"
          spacing={flagTitleSpacing}
          sx={{
            position: 'absolute',
            left: textPosition,
            bottom: textPosition,
            color: 'common.white',
            zIndex: 3,
            right: 4,
          }}
          alignItems="center"
        >
          <Box
            sx={{
              '&& div': {
                width: flagProportion,
                height: flagProportion,
              },
              lineHeight: 0,
            }}
          >
            <CountryFlag round countryCode={artist.countryCode} />
          </Box>
          <Typography
            component="div"
            variant={typographyVariant}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {artist.name}
          </Typography>
        </Stack>
      </Box>
    </NextLink>
  )
}
