import {
  Box,
  ImageList,
  ImageListItem,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { scrollableRowStyles } from 'src/lib/scrollable-row-styles'
import { CarouselPhotos } from '../carousel'
import { IImageWithDescription } from 'src/api'
import { ResponsiveImage } from '../responsive-image'

type Props = {
  photos: Array<IImageWithDescription>
}

const gridColumns = 12 as const
const galleryColumns = 3 as const
const minimumColumnSpan = gridColumns / galleryColumns

export const PhotoGallery = ({ photos }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const [slide, setSlide] = useState<number | null>(null)

  const completedRowsCount = Math.floor(
    (photos.length * minimumColumnSpan) / gridColumns,
  )
  const imagesInUncompletedRow = photos.length % galleryColumns
  const columnSpanForUncompletedRow =
    imagesInUncompletedRow === 1
      ? gridColumns / 2
      : gridColumns / imagesInUncompletedRow

  return (
    <>
      <CarouselPhotos
        carouselPhotos={photos.map((photo) => ({
          fileKey: photo.file.fileKey,
          url: photo.file.resizedUrls.MEDIUM,
          description: photo.description,
        }))}
        slideNumber={slide}
        setSlideNumber={setSlide}
      />
      {isMobile ? (
        <Box>
          <Stack direction="row" spacing={2.25} sx={scrollableRowStyles}>
            {photos.map((photo, index) => (
              <Box
                onClick={() => setSlide(index)}
                key={photo.file.fileKey}
                sx={{
                  '& .photoGallery': {
                    borderRadius: '12px',
                  },
                  cursor: 'pointer',
                  flexShrink: 0,
                  height: '200px',
                  width: '200px',
                }}
                position="relative"
              >
                <ResponsiveImage
                  originalSizeUrl={photo.file.url}
                  className="photoGallery"
                  alt={photo.description}
                  srcSet={{ MOBILE: photo.file.resizedUrls.MEDIUM }}
                />
              </Box>
            ))}
          </Stack>
        </Box>
      ) : (
        <ImageList cols={gridColumns} rowHeight={240} gap={16}>
          {photos.map((photo, index) => (
            <ImageListItem
              key={photo.file.fileKey}
              sx={{
                position: 'relative',
                '& .photoGallery': {
                  borderRadius: '12px',
                },
                cursor: 'pointer',
              }}
              onClick={() => setSlide(index)}
              cols={
                index * minimumColumnSpan + 1 <=
                gridColumns * completedRowsCount
                  ? minimumColumnSpan
                  : columnSpanForUncompletedRow
              }
            >
              <ResponsiveImage
                originalSizeUrl={photo.file.url}
                className="photoGallery"
                srcSet={{ MOBILE: photo.file.resizedUrls.MEDIUM }}
                alt={photo.description}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </>
  )
}
