import { Chip, Grid } from '@mui/material'
import { Box } from '@mui/system'

type Tag = {
  name: string
}

type Props = {
  tags: Array<Tag>
}

export const Tags = ({ tags }: Props) => {
  return (
    <Box>
      <Grid
        container
        direction="row"
        flexWrap="wrap"
        columnSpacing={{ xs: 1 }}
        rowSpacing={{ xs: 1 }}
        sx={{
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >
        {tags.map((tag) => (
          <Grid item key={tag.name} sx={{ maxWidth: '100%' }}>
            <Chip variant="tag" label={tag.name} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
