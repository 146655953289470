import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {
  Box,
  Stack,
  Button,
  CardMedia,
  IconButton,
  useMediaQuery,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { UUID } from 'io-ts-types'
import { ArrowNextIcon, ArrowPreviousIcon, CrossIcon } from 'src/assets/icons'

export type CarouselPhoto = {
  fileKey: UUID
  url: string
  description: string
}

type Props = Readonly<{
  carouselPhotos: Array<CarouselPhoto>
  initialSlide: number
  onClose: () => void
}>

export const CarouselPhotosDetails = ({
  carouselPhotos,
  initialSlide,
  onClose,
}: Props) => {
  const [currentSlide, setCurrentSlide] = useState(initialSlide)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))

  const textTypography = theme.typography.bodyBold

  return (
    <Box
      sx={{
        '&& .carousel-slider': {
          position: 'static',
          overflow: 'visible',
        },
        '&& .carousel-status': {
          top: '24px',
          left: '24px',
          p: 0,
          m: 0,
          ...textTypography,
        },
        '&& .slider-wrapper': {
          overflowY: { xs: 'visible', sm: 'hidden' },
        },
      }}
    >
      <Carousel
        dynamicHeight={isMobile ? false : true}
        onChange={setCurrentSlide}
        renderArrowNext={(clickHandler, hasNext) =>
          hasNext && (
            <Button
              onClick={clickHandler}
              sx={{
                position: 'absolute',
                top: '50%',
                right: 0,
                width: '50px',
                height: '100%',
                minWidth: '50px',
                p: 0,
                transform: 'translate(0, -50%)',
                zIndex: 2,
                backgroundColor: 'rgba(0, 0, 0 ,0.2)',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0 ,0.3)' },
              }}
            >
              <ArrowNextIcon />
            </Button>
          )
        }
        renderArrowPrev={(clickHandler, hasPrevious) =>
          hasPrevious && (
            <Button
              onClick={clickHandler}
              sx={{
                position: 'absolute',
                top: '50%',
                left: 0,
                width: '50px',
                height: '100%',
                minWidth: '50px',
                p: 0,
                transform: 'translate(0, -50%)',
                zIndex: 2,
                backgroundColor: 'rgba(0, 0, 0 ,0.2)',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0 ,0.3)' },
              }}
            >
              <ArrowPreviousIcon />
            </Button>
          )
        }
        selectedItem={currentSlide}
        showIndicators={false}
        showStatus={isMobile ? true : false}
        showThumbs={false}
        useKeyboardArrows
        showArrows={isMobile ? false : true}
      >
        {carouselPhotos.map((photo) => (
          <Stack
            key={photo.fileKey}
            sx={{ position: 'relative', height: { xs: '85vh', sm: 'auto' } }}
            alignItems="center"
            justifyContent="center"
          >
            <CardMedia
              component="img"
              image={photo.url}
              alt={photo.description}
            />
            {photo.description && isMobile && (
              <Typography
                color="common.white"
                sx={{
                  ...textTypography,
                  position: 'absolute',
                  bottom: '0',
                  left: '24px',
                }}
              >
                {photo.description}
              </Typography>
            )}
            {photo.description && isTablet && (
              <Box
                sx={{
                  width: '80%',
                  position: 'absolute',
                  bottom: '16px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  p: '8px 32px',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  borderRadius: '12px',
                }}
              >
                <Typography variant="hintsMedium" color="common.white">
                  {photo.description}
                </Typography>
              </Box>
            )}
          </Stack>
        ))}
      </Carousel>
      <IconButton
        onClick={onClose}
        sx={{
          width: { xs: 24, sm: 40 },
          height: { xs: 24, sm: 40 },
          position: 'absolute',
          top: { xs: '24px', sm: 0 },
          right: { xs: '24px', sm: 5 },
          zIndex: 3,
          backgroundColor: { xs: 'common.white', sm: 'transparent' },
          '&:hover': { backgroundColor: 'rgba(234, 233, 233, 0.3)' },
        }}
      >
        <CrossIcon
          sx={{
            fontSize: { xs: 14, sm: 24 },
            color: {
              xs: 'common.black',
              sm: 'common.white',
            },
          }}
        />
      </IconButton>
    </Box>
  )
}
