import { Box, Grid, Stack } from '@mui/material'
import { IArtist, IEventArtist } from 'src/api'
import { scrollableRowStyles } from 'src/lib/scrollable-row-styles'
import { EnumType } from 'src/lib/types'
import { CardSize, ArtistCard } from './artist-card'

type Props = {
  artists: Array<IArtist>
  cardSize: EnumType<CardSize>
  columns: number
}

export const subHeadingContentSpacing = { xs: 1.5, lg: 2 } as const

export const eventArtistsToArtistSection = (artists: Array<IEventArtist>) =>
  artists.map((artist) => artist.performer)

export const ArtistActSection = ({ artists, cardSize, columns }: Props) => {
  const itemGridWidth = 12 / columns

  return (
    <Box>
      <Box display={{ xs: 'none', lg: 'block' }}>
        <Grid
          container
          columnSpacing={{ lg: 2, xl: 2.5 }}
          rowSpacing={{ lg: 3, xl: 2 }}
        >
          {artists.map((artist) => (
            <Grid item key={artist.performerId} xs={itemGridWidth}>
              <ArtistCard artist={artist} size={cardSize} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box display={{ lg: 'none' }}>
        <Stack spacing={{ xs: 2 }} sx={scrollableRowStyles} direction="row">
          {artists.map((artist) => (
            <ArtistCard
              artist={artist}
              size={cardSize}
              key={artist.performerId}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  )
}
